import bannerico from "./assets/gamepic1.jpg";
import gamepics from "./assets/eldering.jpg";
import freefire from "./assets/freefire.jpg";
const allMessage=[{
    id:2,
    
    
    Message:"the Elden Ring pc specs of a GeForce GTX 660 or an Radeon HD 7850 GPU you can ensure your gaming experience is free from stuttering or crashing. However to experience Elden Ring the developers intended, your computer should meet the system recommended requirements of a GTX 1070 or an Radeon RX Vega 56 GPU to ensure a flawless rendition of the games",
   date:10-20-30,
   from_id:2,
   to_id:1,

},
{
    id:3,
    
    
    Message:"the Elden Ring pc specs of a GeForce GTX 660 or an Radeon HD 7850 GPU you can ensure your gaming experience is free from stuttering or crashing. However to experience Elden Ring the developers intended, your computer should meet the system recommended requirements of a GTX 1070 or an Radeon RX Vega 56 GPU to ensure a flawless rendition of the games",
   date:10-20-30,
   from_id:2,
   to_id:1,

},
{
    id:4,
    
    
    Message:"the Elden Ring pc specs of a GeForce GTX 660 or an Radeon HD 7850 GPU you can ensure your gaming experience is free from stuttering or crashing. However to experience Elden Ring the developers intended, your computer should meet the system recommended requirements of a GTX 1070 or an Radeon RX Vega 56 GPU to ensure a flawless rendition of the games",
   date:10-20-30,
   from_id:1,
   to_id:2,

},
{
    
    id:5,
    
    
    Message:"the Elden Ring pc specs of a GeForce GTX 660 or an Radeon HD 7850 GPU you can ensure your gaming experience is free from stuttering or crashing. However to experience Elden Ring the developers intended, your computer should meet the system recommended requirements of a GTX 1070 or an Radeon RX Vega 56 GPU to ensure a flawless rendition of the games",
   date:10-20-30,
   from_id:3,
   to_id:5 ,

}

]
export function getmessage() {
    return allMessage;
  }