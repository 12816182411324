import React from 'react'
import bannerico from "./assets/gamepic1.jpg";

export default function GameBanner({images}) {
  return (
    <div className='GameBanners' style={{ backgroundImage: `url(${images})`}}>
        
        
    </div>
  )
}
