
import './App.css';

import Mbody from './Body';
import Rightbody from './Rightbody';




function App() {
  
  return (
   
      <body>
      
        <Mbody />
    
        <Rightbody/>

      

      </body>
        
      
   
  );
}
export default App;
