import bannerico from "./assets/callof duty.jpg";
import gamepics from "./assets/s1.jpg";

const channel=[{
    id:1,
    name:"Gta Fans",
    image:gamepics,
    Message:"i love gta",
   

},
{
    id:3,
    name:"ALL of us",
    image:bannerico,
    Message:"what do you think",
}
]
export function getchannel() {
    return channel;
  }